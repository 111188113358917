<template>
  <b-form-checkbox-group v-model="selectedIds">
    <div>
      <div v-if="notificationsList.length > 0">
        <b-row>
          <!--add card notification-->
          <b-col cols="12" class="mt-2">
            <b-card no-body class="card">
              <template #header style="display: flex; justify-content: space-between">
                <div class="d-flex">
                  <b-form-checkbox v-model="allSelected" @change="selectAll">
                  </b-form-checkbox>
                  <p>تحديد الكل</p>
                </div>
                <b-button variant="transparnt" class="p-0" @click="deleteNotification">
                  <unicon fill="red" height="18px" name="trash-alt"> </unicon>
                </b-button>
              </template>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            v-for="notifications in notificationListData"
            :key="notifications.id"
          >
            <b-card style="margin-top: -25px" class="card">
              <template #header style="display: flex; justify-content: space-between">
                <div>
                  <b-form-checkbox :value="notifications.id"> </b-form-checkbox>
                  {{ notifications.title }}
                </div>
                <div class="d-flex">
                  <b-badge
                    pill
                    variant="light-warning"
                    class="mr-1"
                    v-if="notifications.notificationType == 'Student'"
                  >
                    {{ notifications.sendToCount }} من تطبيق الطلاب
                  </b-badge>
                  <b-badge
                    pill
                    class="mr-1"
                    variant="light-primary"
                    v-if="notifications.notificationType == 'SellPoint'"
                  >
                    {{ notifications.sendToCount }} من نقاط البيع</b-badge
                  >
                  <div>
                    {{ formDate(notifications.date) }}
                    <b-button variant="transparnt" class="p-0">
                      <unicon name="calendar-alt" height="20px" fill="gray"></unicon>
                    </b-button>
                  </div>
                </div>
              </template>
              <b-card-text>
                {{ notifications.body }}
              </b-card-text>
            </b-card>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-center">
            <ek-pagination
              :items="notificationsList"
              v-model="notificationListData"
              :pageLength="5"
            />
          </b-col>
        </b-row>
      </div>
      <div v-else class="text-center mt-2">
        <h4>لا يوجد اشعارات</h4>
      </div>
    </div>
  </b-form-checkbox-group>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState({
      notifications: ({ notifications }) => notifications.notifications,
      sellPointName: ({ sellPoints }) => sellPoints.sellPointName,
    }),
    ...mapGetters(["notificationsList"]),
  },
  data: () => ({
    notificationListData: [],
    selectedIds: [],
    allSelected: false,
  }),
  methods: {
    ...mapActions([
      "getNotificationsList",
      "deleteNotificationsRang",
      "getNameSellPoints",
      "getFacultyName",
      "getStudentsName",
      "getGetUnRead"
    ]),
    formDate(date) {
      let dete = `${new Date(date).toLocaleDateString()}`;
      return dete;
    },
    deleteNotification() {
      this.deleteNotificationsRang(this.selectedIds);
    },
    //delete selectAll
    selectAll() {
      if (this.allSelected) {
        const selectedIds = this.notifications.map((u) => u.id);
        this.selectedIds = selectedIds;
      } else {
        this.selectedIds = [];
      }
    },
  },
  created() {
    this.getNotificationsList();
    this.getNameSellPoints();
    this.getFacultyName();
    this.getStudentsName();
    this.getGetUnRead()
  },
};
</script>
<style scoped>
.card {
  border-radius: 0px;
}
.card .card-header {
  padding: 0.5rem !important;
}
</style>
